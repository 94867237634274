.sermons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  
  h1 {
    font-size: 2.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #65878e;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  