.postDiv {
    margin: 2rem;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 1rem;
    box-shadow: 0px 0px 5px #b2b2b2;
  }
  
  .postHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .postHeader h1 {
    font-size: 1.5rem;
    margin: 0;
    color: #65878e;
  }
  
  .postTextContainer {
    font-size: 1.2rem;
    color: #3b3b3b;
    margin-bottom: 1rem;
  }
  
  .postDiv > div {
    font-size: 1rem;
    color: #3b3b3b;
  }
  

  .postInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #65878e;
    font-size: 14px;
  }
  
  .postInfo div {
    margin-right: 10px;
  }