.App{
    text-align:center;
}

body {
    padding: 0%;
    margin: 0%;
    font-family: Arial, Helvetica, sans-serif;
}

/* nav bar styling  */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #65878ece;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

nav a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
}

nav button {
  background-color: #e26d5c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
}

nav button:hover {
  text-decoration: underline;
}

nav a:last-child {
  margin-right: 0;
}

nav a.active {
  font-weight: bold;
  background-color: #5a8b94;
}


/* a {
    color: rgb(183, 162, 125);
    text-decoration: none;
    margin: 10px;
    font-size: 25px;
} */


nav img {
  height: 75px;
  margin-left: 10px;
}